.vjs-context-menu-ui {
  position: absolute;
  z-index: 2;

  .vjs-menu-content {
    background: rgba(0,0,0,.6);
    border-radius: 0.2em;
    padding: 0;
  }

  .vjs-menu-item {
    // Override video.js styles for menus
    font-size: 1em;
    line-height: 1em;
    text-transform: none;

    cursor: pointer;
    margin: 0;
    padding: 0.8em 1.4em;

    &:active, &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .player-version {
    font-size: 80%;
    opacity: .7;
  }
}
