@import '../../assets/styles/mixins/aspect-ratio';
@import '../../assets/styles/mixins/mixins.scss';

.vjs-recommendations-overlay {
  display: flex;
  align-items: center;
  justify-content: center;

  visibility: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(#000000, 0.5);

  .vjs-recommendations-overlay-hide {
    font-size: 1em;
    cursor: pointer;
    position: absolute;
    display: inline-block;
    top: 3.75%;
    right: 1.72%;
  }

  .vjs-recommendations-overlay-content {
    position: relative;
    width: 85%;

    @include aspect-ratio(16, 9);

    .aspect-ratio-content {
      display: flex;
      flex-flow: column;

      height: auto;

      .vjs-recommendations-overlay-item {
        border: 1px solid rgba(#FFFFFF, 0.5);
      }

      .vjs-recommendations-overlay-item-primary {
        flex: 1.82;
        display: flex;
        flex-flow: row;

        .vjs-recommendations-overlay-item-primary-image {
          flex: 1;
          background-size: cover;
        }

        .vjs-recommendations-overlay-item-primary-content {
          flex: 0.5625;
          display: flex;
          flex-flow: column;

          background: rgba(#000000, 0.6);
          text-align: left;
          padding: 3%;
          min-width: 0; // for use with flex and white-space: nowrap

          h2 {
            @include ellipsis;
            padding: 0 0 0 0;
            font-size: 18px;
            margin: 0 0 1em 0;
            font-weight: 600;
          }

          h3 {
            @include ellipsis;
            padding: 0 0 0 0;
            font-size: 20px;
            margin: 0 0 1.3em 0;
            font-weight: 500;
          }

          p {
            padding: 0 0 0 0;
            margin: 0 0 0 0;
            font-size: 14px;
            overflow: hidden;
            line-height: 1.4em;
            font-weight: 400;
          }

          @media only screen and (max-width: 1050px) {
            h2 {
              font-size: 16px
            }

            h3 {
              font-size: 18px;
            }

            p {
              font-size: 12px;
            }
          }

          @media only screen and (max-width: 900px) {
            p {
              font-size: 10px;
            }
          }

          @media only screen and (max-width: 768px) {
            p {
              display: none;
            }
          }
        }
      }

      .vjs-recommendations-overlay-item-secondary-container {
        flex: 1;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        .vjs-recommendations-overlay-item-secondary {
          background-size: 100% 100%;
          flex: 1;

          max-width: 33%;

          font-size: 1.16em;
          position: relative;

          margin: 2% 2% 0 0;

          &:last-child {
            margin-right: 0;
          }

          div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            text-align: left;
            line-height: normal;
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            padding: 20% 7% 4.5% 7%;

            .cld-video-player-skin-light & {
              padding: 5% 7%;
            }

            .cld-video-player-skin-dark & {

              &.vjs-recommendations-overlay-item-info {
                text-shadow: 1px 1px 0 rgba(#000, .3);
              }
            }

            span {
              display: block;
              min-width: 0;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;

              &.vjs-recommendations-overlay-item-secondary-title {
                flex: 2.3;
                font-size: 13px;
              }

              &.vjs-recommendations-overlay-item-secondary-duration {
                text-align: right;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
