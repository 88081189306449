.cld-video-player {
  .vjs-vtt-thumbnail-display {
    position: absolute;
    left: 0;
    z-index: 1;
    transition: opacity 0.2s;
    bottom: 3em;
    pointer-events: none;
    border: 1px solid var(--color-base);
    border-radius: 2px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(12px);
    transform: translateX(-50%);
  }

  .vjs-vtt-time-display {
    font-size: 80%;
    line-height: 1.4;
    position: absolute;
    bottom: -2.8em;
    left: 0;
    right: 0;
    margin: auto;
    color: var(--color-text);
    background: var(--color-base);
    padding: 0.3em 0.6em;
    width: fit-content;
    border-radius: 4px;
  }

  .vjs-time-tooltip {
    right: auto !important;
    translate: -50%;
  }
}
