$width: 20%;
.cld-spbl-bar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 1;
}
.cld-spbl-bar-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX($width);
  transition: transform .3s;
}
.cld-video-player .vjs-control-bar {
  transition: width .3s;
}
.shoppable-panel-visible {
  .cld-spbl-bar-inner {
    transform: translateX(0);
  }
  .vjs-control-bar {
    width: 100% - $width;
  }
  .cld-spbl-toggle-icon:before {
    content: "\f119";
  }
}
.cld-spbl-banner-msg {
  position: absolute;
  font-size: 2em;
  text-shadow: 0 0 5px rgba(#000, .5);
  left: 0;
  top: calc(50% + 3em);
  width: 100%;
  text-align: center;
  .cld-video-player.vjs-has-started &,
  .cld-video-player.vjs-error & {
    display: none;
  }
}
.cld-spbl-toggle {
  position: absolute;
  top: 3%;
  right: $width;
  color: inherit;
  border-radius: 6px 0 0 6px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  pointer-events: all;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}
.cld-spbl-toggle-icon {

  // Custom toggle icon
  &.cld-spbl-toggle-custom-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 75%;
    .shoppable-panel-hidden & {
      &:before {
        display: none;
      }
    }
    .shoppable-panel-visible & {
      background-image: none !important;
    }
  }

  // Animate
  display: block;
  animation-duration: 1s;
  animation-fill-mode: both;
  &.animate,
  .shoppable-panel-hidden &:hover {
    animation-name: tada;
    animation-iteration-count: infinite;
  }

}
.cld-spbl-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: $width;
  overflow: auto;
  pointer-events: all;

  // Hide scrollbar
  -ms-overflow-style: none;  // Internet Explorer 10+
  scrollbar-width: none;  // Firefox
  &::-webkit-scrollbar {
    display: none;  // Safari and Chrome
  }
}
.cld-spbl-item {
  display: block;
  position: relative;
  margin: 12px 8px;
  color: inherit;
  transform: scale(0.98);
  transition: transform .3s;

  .cld-spbl-bar[size="md"] & {
    font-size: 10px;
  }
  .cld-spbl-bar[size="sm"] & {
    font-size: 8px;
  }

  &:before,
  &:after {
    content:  "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
    box-shadow: 0 0 0;
    transition: box-shadow .3s;
    border-radius: 3px;
  }
  &.active {
    transform: scale(1);
    &:before {
      opacity: .5;
      box-shadow: 0 0 0 4px;
    }
    &:after {
      box-shadow: 0 0 0 2px;
    }

  }
  &:hover {
    color: inherit;
  }
}
.cld-spbl-img {
  max-width: 100%;
  height: auto;
}
.cld-spbl-hover-img {
  display: none;
}
.cld-spbl-item-info {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: 100%;
  overflow: hidden;
  padding: 6%;
}
.cld-spbl-overlay {
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: 1.3;
  font-size: 120%;
  text-align: center;
  padding: 10%;
  z-index: 1;
  opacity: 0;

  .cld-spbl-overlay-text {
    margin: auto;
  }
}
.cld-spbl-item:hover {
  .cld-spbl-overlay {
    opacity: 1;
    ~ .cld-spbl-item-info {
      display: none;
    }
  }
  .cld-spbl-hover-img {
    display: block;
    + {
      .cld-spbl-img {
        display: none;
      }
    }
  }
}

.cld-spbl-post-play {
  .cld-spbl-post-play-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
  }
  .cld-spbl-post-title {
    position: absolute;
    font-size: 1.5em;
    text-shadow: 0 0 5px rgba(#000, .5);
    left: 0;
    top: 0.8em;
    width: 100%;
    text-align: center;
  }
  .cld-spbl-panel {
    width:  100%;
    top: 16%;
    height: 60%;
    padding: 10px 8px;
    white-space: nowrap;
    background: none !important;

    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
  .cld-spbl-item {
    display: inline-block;
    width: 28%;
    padding-top: 28%;
    margin: 0 8px;
    white-space: normal;
    vertical-align: top;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px 1.2px rgba(0,0,0,0.24), 0 6px 8px 4px rgba(0,0,0,0.24);
    transform: none;
  }
  .cld-spbl-img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto auto;
  }
  .cld-spbl-replay-btn {
    position: absolute;
    left: 5%;
    bottom: 10%;
    font-family: inherit;
    padding: 8px 15px;
    border-radius: 5px;
    &:before {
      font-family: VideoJS;
      vertical-align: bottom;
      margin-right: 4px;
    }
  }
}

.cld-spbl-post-modal {
  .cld-spbl-bar,
  .vjs-big-play-button,
  .vjs-recommendations-overlay {
    display: none;
  }
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

// cld-spbl-products-overlay
.shoppable-products-overlay {
  .vjs-title-bar,
  .vjs-big-play-button {
    display: none !important;
  }
}
.cld-spbl-products-overlay {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.cld-spbl-product-hotspot {
  pointer-events: all;
  position: absolute;
  cursor: pointer;
  padding: 15px;
  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background: #fff;
    box-shadow: 0 0 0 4px #0078FF, 0 0 0 8px rgba(0,120,255,0.24);
    border-radius: 18px;
  }

  &:hover .cld-spbl-product-tooltip {
    display: block;
  }
}
.cld-spbl-product-tooltip {
  display: none;
  position: absolute;
  white-space: nowrap;
  padding: 4px 6px;
  border-radius: 4px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  &.cld-spbl-product-tooltip-top {
    top: auto;
    bottom: 100%;
  }
  &.cld-spbl-product-tooltip-left {
    top: 50%;
    right: 100%;
    left: auto;
    transform: translateY(-50%);
  }
  &.cld-spbl-product-tooltip-right {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
  }

}
