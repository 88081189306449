.cld-thumbnail {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  font-size: 1em;
  text-align: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .cld-thumbnail-img {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 40%;
    max-height: 60%;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, var(--color-base), transparent 80%);
    opacity: 0.6;
  }

  &.cld-plw-panel-item-active {
    border: 1px solid var(--color-accent);
    box-sizing: border-box;
    .cld-plw-item-info-wrap {
      color: var(--color-accent);
    }
  }
}

.cld-plw-panel-item:hover:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: var(--color-text);
  opacity: 0.2;
}

@media only screen and (max-width: 768px) {
  .cld-thumbnail:before {
    background: none;
  }
}
