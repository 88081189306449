.cld-video-player {
  .vjs-http-source-selector {
    > .vjs-button {
      > .vjs-icon-placeholder {
        font-family: VideoJS;
        font-weight: 400;
        font-style: normal;
        &:before {
          content: "\f114";
        }
      }
    }
  }
}
