@use "sass:math";

$interaction-area-marker-size: 20px;
$interaction-area-marker-border-size: 2px;
$interaction-area-marker-box-shadow-extra-size: 4px;
$interaction-area-layout-icon-height: 60px;

.cld-video-player{

  &.vjs-user-active {
    .interaction-areas-container {
      display: block;
    }
  }

  &.interaction-areas {

    .vjs-big-play-button {
      display: none !important;
    }
  }

  .interaction-areas-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    z-index: 1;

    &:hover {
      display: block;
    }

    .go-back-button,
    .vp-ia-item {
      position: absolute;
      cursor: pointer;
    }

    .go-back-button {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .vp-ia-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: top, left, width, height;
      transition-timing-function: linear;

      &.theme-shadowed {

        .vp-ia-marker-shadow{
          opacity: 0.24;
        }

      }

      &.theme-pulsing {

        .vp-ia-marker-shadow {
          -webkit-animation: pulsing-animation 1.3s ease-out;
          -webkit-animation-iteration-count: infinite;
          border: solid $interaction-area-marker-border-size;
          opacity: 0;
        }
      }

      &:hover {
        .vp-ia-marker-main:before {
          content: "";
          width: $interaction-area-marker-size;
          height: $interaction-area-marker-size;
          border: solid $interaction-area-marker-border-size rgba(0,0,0,0.25);
        }
      }

      .vp-ia-area-marker {
        position: relative;
        width: $interaction-area-marker-size;
        height: $interaction-area-marker-size;

        .vp-ia-marker-main,
        .vp-ia-marker-main:before,
        .vp-ia-marker-shadow {
          position: absolute;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
        }

        .vp-ia-marker-main {
          background-color: white;
          border:solid $interaction-area-marker-border-size;
          height: 100%;
          width: 100%;
        }

        .vp-ia-marker-shadow {
          width: 2*$interaction-area-marker-border-size + $interaction-area-marker-size + $interaction-area-marker-box-shadow-extra-size;
          height:2*$interaction-area-marker-border-size + $interaction-area-marker-size + $interaction-area-marker-box-shadow-extra-size;
        }
      }
    }

    &.vp-ia-layout-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);

      &.clickable {
        cursor: pointer;

        &:hover .vp-theme-button {
          background-color: rgba(255, 255, 254, 0.28);
        }

      }

      .vp-ia-layout-icon {
        margin-bottom: 20px;
        height: $interaction-area-layout-icon-height;
      }

      .vp-ia-layout-message-title {
        font-size: 24px;
        line-height: 34px;
        text-align: center;
      }

      .vp-theme-button {
        margin: 8px 0 18px 0;
      }

      .vp-ia-layout-message-do-not-show {
        display: flex;
        align-items: center;

        > * {
          cursor: pointer;
        }

        .vp-ia-layout-message-checkbox-title {
          margin: 0 0 0 5px;
        }

      }
    }
  }
}

@-webkit-keyframes pulsing-animation {
  0%   { height: math.div($interaction-area-marker-size, 2); width: math.div($interaction-area-marker-size, 2); opacity: 0}
  50%  { height: $interaction-area-marker-size; width: $interaction-area-marker-size; opacity: 1}
  100% { height: 2 * $interaction-area-marker-size; width: 2 * $interaction-area-marker-size; opacity: 0}
}
