.cld-video-player-floater {
  position: static;
  transition: all 0.5s ease-out;
  transition-property: bottom, right, left;

  .cld-video-player-floater-close {
    display: none;
  }

  // Floated
  &.cld-video-player-floating {
    position: fixed;
    z-index: 9;
    border: 4px solid #fff;
    box-shadow: 0 3px 10px rgba(#000, .2);

    .cld-video-player-floater-inner {
      height: 0;
      width: 100%;
    }

    .cld-video-player-floater-close {
      position: absolute;
      bottom: 100%;
      background: #fff;
      border-bottom: none;
      margin-bottom: 4px;
      right: -4px;
      padding: 4px 6px 2px;
      display: block;
      box-shadow: 0 0 4px rgba(#000, .05);
      cursor: pointer;
      // Hide bottom shadow
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        right: 0;
        width: 120%;
        height: 4px;
        background: #fff;
      }
    }

    .vjs-time-control,
    .vjs-upcoming-video-title-display,
    .vjs-recommendations-overlay {
      display: none;
    }
    .vjs-upcoming-video .vjs-upcoming-video-bar {
      background-color: transparent;
    }

    .vjs-big-play-button {
      font-size: 4em;
      top: 40%;
    }

    &.cld-video-player-floater-bottom-right {
      bottom: 20px !important;
      right: 20px !important;
      top: auto !important;
      left: auto !important;
    }

    &.cld-video-player-floater-bottom-left {
      bottom: 20px !important;
      left: 20px !important;
      top: auto !important;
      right: auto !important;
    }

  }
}
