.cld-video-player {

  .vjs-playlist-button {
    display: none;
  }

  &.vjs-playlist {

    .vjs-playlist-button {
      display: block;
    }
  }

}

