.cld-video-player {
  .ima-fullscreen-div, .ima-mute-div, .ima-play-pause-div, .ima-slider-div {
    line-height: 1.7;
  }

  &.vjs-ad-loading {
    > video,
    > .vjs-poster {
      opacity: 0;
    }
  }
}

.ima-ad-container {
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  video {
    left: 0;
  }
}

/* Move overlay if user fast-clicks play button. */
.video-js.vjs-playing .bumpable-ima-ad-container {
  margin-top: -50px;
}

/* Move overlay when controls are active. */
.video-js.vjs-user-inactive.vjs-playing .bumpable-ima-ad-container {
  margin-top: 0px;
}

.video-js.vjs-paused .bumpable-ima-ad-container,
.video-js.vjs-playing:hover .bumpable-ima-ad-container,
.video-js.vjs-user-active.vjs-playing .bumpable-ima-ad-container {
  margin-top: -50px;
}

.vjs-ima-non-linear .vjs-big-play-button,
.vjs-ima-non-linear .vjs-menu.vjs-settings-menu,
.vjs-ima-non-linear .vjs-info-overlay,
.vjs-ima-non-linear .vjs-modal-overlay,
.vjs-ima-non-linear .vjs-control-bar {
  z-index: 1112;
}

.ima-controls-div {
  bottom:0px;
  height: 37px;
  position: absolute;
  overflow: hidden;
  display: none;
  opacity: 1;
  background-color: rgba(7, 20, 30, .7);
  background: -moz-linear-gradient(
      bottom,
      rgba(7, 20, 30, .7) 0%,
      rgba(7, 20, 30, 0) 100%); /* FF3.6+ */
  background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0%,rgba(7, 20, 30, .7)),
      color-stop(100%,rgba(7, 20, 30, 0))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
      bottom,
      rgba(7, 20, 30, .7) 0%,
      rgba(7, 20, 30, 0) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(bottom,
    rgba(7, 20, 30, .7) 0%,
    rgba(7, 20, 30, 0) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(bottom,
    rgba(7, 20, 30, .7) 0%,
    rgba(7, 20, 30, 0) 100%); /* IE10+ */
  background: linear-gradient(to top,
    rgba(7, 20, 30, .7) 0%,
    rgba(7, 20, 30, 0) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(
    startColorstr='#0007141E',
    endColorstr='#07141E',GradientType=0 ); /* IE6-9 */
}

.ima-countdown-div {
  height: 10px;
  color: #FFFFFF;
  text-shadow: 0 0 0.2em #000;
  cursor: default;
}

.ima-seek-bar-div {
  top: 12px;
  height: 3px;
  position: absolute;
  background: rgba(255, 255, 255, .4);
}

.ima-progress-div {
  width: 0px;
  height: 3px;
  background-color: #ECC546;
}

.ima-play-pause-div, .ima-mute-div, .ima-slider-div, .ima-fullscreen-div {
  width: 35px;
  height: 20px;
  top: 11px;
  left: 0px;
  position: absolute;
  color: #CCCCCC;
  font-size: 1.5em;
  line-height: 2;
  text-align: center;
  font-family: VideoJS;
  cursor: pointer;
}

.ima-mute-div {
  left: auto;
  right: 85px;
}

.ima-slider-div {
  left: auto;
  right: 35px;
  width: 50px;
  height: 10px;
  top: 20px;
  background-color: #555555;
}

.ima-slider-level-div {
  width: 100%;
  height: 10px;
  background-color: #ECC546;
}

.ima-fullscreen-div {
  left: auto;
  right: 0px;
}

.ima-playing:before {
  content: "\00f103";
}

.ima-paused:before {
  content: "\00f101";
}

.ima-playing:hover:before, .ima-paused:hover:before {
  text-shadow: 0 0 1em #fff;
}

.ima-non-muted:before {
  content: "\00f107";
}

.ima-muted:before {
  content: "\00f104";
}

.ima-non-muted:hover:before, .ima-muted:hover:before {
  text-shadow: 0 0 1em #fff;
}

.ima-non-fullscreen:before {
  content: "\00f108";
}

.ima-fullscreen:before {
  content: "\00f109";
}

.ima-non-fullscreen:hover:before, .ima-fullscreen:hover:before {
  text-shadow: 0 0 1em #fff;
}

.video-js.vjs-ad-playing .vjs-control-bar {
  z-index: 1112;
}

.video-js.vjs-ad-playing .vjs-control-bar .vjs-progress-control {
  pointer-events: none;
}

.video-js.vjs-ad-playing .vjs-control-bar>:not(.vjs-play-control):not(.vjs-volume-menu-button):not(.vjs-time-control):not(.vjs-progress-control):not(.vjs-spacer):not(.vjs-fullscreen-control):not(.vjs-gradient):not(.ima-countdown-div),
.video-js.vjs-ad-playing .vjs-control-bar .vjs-mouse-display-tooltip,
.video-js.vjs-ad-playing .vjs-control-bar .vjs-progress-control:before,
.video-js.vjs-ad-playing .vjs-control-bar .vjs-play-progress:before,
.video-js.vjs-ad-playing .vjs-control-bar .vjs-progress-control .vjs-load-progress,
.video-js.vjs-ad-playing .vjs-control-bar .vjs-progress-control .vjs-mouse-display {
  display: none;
}

.video-js.vjs-ad-playing .vjs-control-bar {
  transition: height 0.1s ease;
}

.video-js.vjs-ad-playing.vjs-user-inactive:not(.vjs-ad-paused) .vjs-control-bar {
  opacity: 1;
  height: 0;
}

.video-js.vjs-ad-playing.vjs-user-inactive:not(.vjs-ad-paused) .vjs-control-bar .vjs-progress-holder {
  margin: 0;
}

.video-js.vjs-ad-playing .vjs-control-bar>:not(.vjs-progress-control):not(.ima-countdown-div) {
  transition: opacity 0.1s ease, visibility 0.1s ease;
}

.video-js.vjs-ad-playing .vjs-control-bar>:not(.vjs-progress-control):not(.ima-countdown-div) div{
  transition: line-height 0.1s ease;
}

.video-js.vjs-ad-playing.vjs-user-inactive:not(.vjs-ad-paused) .vjs-control-bar>:not(.vjs-progress-control):not(.ima-countdown-div) {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.video-js.vjs-ad-playing.vjs-user-inactive:not(.vjs-ad-paused) .vjs-control-bar>:not(.vjs-progress-control):not(.ima-countdown-div) div{
  line-height: 0.01em;
}

.video-js.vjs-ad-playing .vjs-control-bar .vjs-progress-control .vjs-thumbnail-holder,
.video-js.vjs-ad-playing .vjs-control-bar .vjs-progress-control .vjs-thumbnail {
  display: none !important;
}

.video-js.vjs-ad-playing .vjs-control-bar .vjs-progress-control .vjs-progress-holder {
  cursor: auto;
  box-shadow: none;
  font-size: 100%;
  margin: 0 1em;
}

.video-js .vjs-control-bar .ima-countdown-div {
  display: none;
  z-index: 1;
}

.video-js.vjs-ad-playing .vjs-control-bar .ima-countdown-div {
  display: block;
  position: absolute;
  margin-top: -2em;
  left: 2em;
  font-size: 1.2em;
}
