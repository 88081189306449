.cld-video-player {
  .vjs-highlights-graph-display {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50%;
    z-index: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s;
    svg {
      width: 100%;
      height: 100%;
    }
    path {
      fill: currentColor;
    }
  }

  .vjs-progress-control:hover .vjs-highlights-graph-display {
    opacity: 0.8;
  }
}
